<template>
  <div>
    <b-card no-body>
      <b-card-header class="d-block">
        <b-card-title>
          {{ lang("t_campaigns") }}
        </b-card-title>
        <!-- <b-card-sub-title class="mt-1">
          {{ lang("t_campaignList") }}
        </b-card-sub-title> -->
      </b-card-header>

      <b-card-body class="px-0">
        <b-card-text class="d-flex mx-2">
          <b-input-group class="input-group-merge">
            <b-input-group-prepend is-text>
              <feather-icon icon="SearchIcon" />
            </b-input-group-prepend>

            <b-form-input
              v-model="searchQuery"
              :placeholder="lang('t_search')"
            ></b-form-input>
          </b-input-group>

          <b-button
            @click="
              modal_record_details = true;
              isEdit = false;
              reset_record();
            "
            size="sm"
            class="px-50 py-50 ml-1"
            variant="flat-secondary"
          >
            <b-icon icon="plus"></b-icon>
          </b-button>

          <!-- <b-button size="sm" class="px-50 py-50" variant="flat-secondary">
            <b-icon icon="three-dots"></b-icon>
          </b-button> -->
        </b-card-text>
        <b-table
          :filter="searchQuery"
          small
          hover
          responsive
          :items="items"
          :fields="tableColumns"
          :sort-by.sync="sortBy"
          :empty-text="lang('t_noRecord')"
          :sort-desc.sync="isSortDirDesc"
        >
          <template #cell(campaign_status)="data">
            <b-spinner
              v-if="data.value == 'active'"
              :variant="'success'"
              small
              type="grow"
            />
            <b-icon
              v-else
              variant="danger"
              scale="1"
              icon="circle-fill"
            ></b-icon>
          </template>
          <template #cell(project)="data">
            {{
              projects.find((e) => e.internal_name == data.item.project)
                ? projects.find((e) => e.internal_name == data.item.project)
                    .display_name
                : data.item.project
            }}
          </template>
          <template #cell(filter)="data">
            {{
              data.item.filter.map((datum) => {
                return `${datum.column} ${datum.operand} ${datum.value}`;
              })
            }}
          </template>

          <!-- Column: Actions -->
          <template #cell(actions)="data">
            <b-dropdown
              dropleft
              variant="link"
              no-caret
              :right="$store.state.appConfig.isRTL"
              boundary="viewport"
            >
              <template #button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="align-middle text-body"
                />
              </template>
              <b-dropdown-item @click="StartCampaign(data.item)">
                <feather-icon icon="PlayIcon" />
                <span class="align-middle ml-50">{{ lang("t_start") }}</span>
              </b-dropdown-item>
              <b-dropdown-item @click="StopCampaign(data.item)">
                <feather-icon icon="StopCircleIcon" />
                <span class="align-middle ml-50">{{ lang("t_stop") }}</span>
              </b-dropdown-item>
              <b-dropdown-item @click="RecycleCampaign(data.item)">
                <feather-icon icon="RefreshCwIcon" />
                <span class="align-middle ml-50">{{ lang("t_recycle") }}</span>
              </b-dropdown-item>
              <b-dropdown-item @click="updateSelected(data.item)">
                <feather-icon icon="EditIcon" />
                <span class="align-middle ml-50">{{ lang("t_edit") }}</span>
              </b-dropdown-item>

              <b-dropdown-item @click="confirmText(data.item)">
                <feather-icon icon="TrashIcon" />
                <span class="align-middle ml-50">{{ lang("t_delete") }}</span>
              </b-dropdown-item>
            </b-dropdown>
          </template>
        </b-table>
      </b-card-body>

      <b-card-footer class="py-75 border-0">
        <div class="d-flex justify-content-between d-flex align-items-center">
          <div class="d-flex align-items-center mb-0">
            <!-- <span class="text-nowrap"> Showing 1 to </span> -->
            <b-form-select
              size="sm"
              v-model="perPage"
              :options="['10', '25', '50']"
              class="mr-1"
              @input="
                (value) => props.perPageChanged({ currentPerPage: value })
              "
            />
            <span class="text-nowrap text-muted font-small-3">
              {{
                lang("t_total") +
                " " +
                records.length +
                " " +
                lang("t_row").toLowerCase()
              }}
            </span>
          </div>

          <b-pagination
            size="sm"
            class="mb-0"
            v-model="currentPage"
            :per-page="perPage"
            align="right"
            :total-rows="records.length"
            first-number
            last-number
          >
            <template #prev-text>
              <feather-icon icon="ChevronLeftIcon" size="16" />
            </template>
            <template #next-text>
              <feather-icon icon="ChevronRightIcon" size="16" />
            </template>
          </b-pagination>
        </div>
      </b-card-footer>
    </b-card>

    <b-modal
      no-close-on-esc
      no-close-on-backdrop
      :title="isEdit ? lang('t_edit') : lang('t_add')"
      centered
      size="lg"
      scrollable
      v-model="modal_record_details"
      :ok-title="lang('t_save')"
      :cancel-title="lang('t_cancel')"
      @ok="add_records"
      dialog-class="dClass75"
    >
      <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
        <!-- Form -->
        <b-form
          style="min-height: 60vh; overflow-y: auto"
          class="p-2"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
        >
          <!-- Project -->
          <b-row>
            <b-col cols="12">
              <div class="d-flex align-items-center">
                <validation-provider
                  class="w-100 mr-50"
                  #default="validationContext"
                  name="Project"
                  rules="required"
                >
                  <b-form-group :label="lang('t_project')" label-for="project">
                    <v-select
                      v-model="currentData.project"
                      :options="projects"
                      label="display_name"
                      :reduce="(val) => val.internal_name"
                      @input="get_columns"
                      :clearable="false"
                    />
                    <b-form-invalid-feedback>
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
                <validation-provider
                  class="w-100 ml-50"
                  #default="validationContext"
                  name="Name"
                  rules="required"
                >
                  <b-form-group
                    :label="lang('t_displayName')"
                    label-for="display_name"
                  >
                    <b-form-input
                      id="display_name"
                      v-model="currentData.display_name"
                      autofocus
                      trim
                    />

                    <b-form-invalid-feedback>
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
                <b-button
                  v-if="currentData.project"
                  class="ml-1 py-50 px-50"
                  variant="flat-secondary"
                  @click="repeateAgain"
                  v-b-tooltip.hover.v-secondary
                  v-b-tooltip.hover.bottom="lang('t_addFilter')"
                >
                  <feather-icon icon="PlusIcon" />
                </b-button>
              </div>
            </b-col>
          </b-row>
          <b-row>
            <b-form-radio
              class="ml-1 mb-1"
              v-model="currentData.mode"
              name="dialer"
              value="dialer"
            >
              {{ lang("t_algorithmicDialer") }}
            </b-form-radio>
            <b-form-radio
              class="ml-1 mb-1"
              v-model="currentData.mode"
              name="power"
              value="power"
            >
              {{ lang("t_basicDialer") }}
            </b-form-radio>
          </b-row>
          <b-row>
            <b-form-checkbox
              class="mb-1 ml-1"
              v-model="currentData.auto_recycle"
            >
              {{ lang("t_autoRecycle") }}
            </b-form-checkbox>
          </b-row>
          <b-row>
            <b-form-checkbox
              class="mb-1 ml-1"
              v-model="currentData.is_abandoned_campaign"
            >
              {{ lang("t_abandonedCampaign") }}
            </b-form-checkbox>
          </b-row>

          <div>
            <div>
              <h4>{{ lang("t_filters") }}</h4>
              <b-form
                style="max-height: 20vh; overfilow-y: auto"
                ref="form"
                :style="{ height: trHeight }"
                class="repeater-form"
                @submit.prevent="repeateAgain"
              >
                <!-- Row Loop -->
                <b-row
                  v-for="(item, index) in currentData.filter"
                  :id="item.id"
                  :key="index"
                  ref="row"
                >
                  <!-- Item Name -->
                  <b-col md="4" sm="6">
                    <b-form-group
                      :label="lang('t_columnName')"
                      label-for="profile"
                    >
                      <v-select
                        label="display_name"
                        :reduce="(val) => val.internal_name"
                        :options="columns"
                        v-model="item.column"
                      >
                        <!-- <template slot="option" slot-scope="option">
                          <span>{{
                            column_labels.hasOwnProperty(option.label)
                              ? column_labels[option.label]
                              : option.label
                          }}</span>
                        </template> -->
                      </v-select>
                    </b-form-group>
                  </b-col>

                  <!-- Cost -->
                  <b-col md="2" sm="6">
                    <b-form-group
                      :label="lang('t_operator')"
                      label-for="t_operator"
                    >
                      <v-select
                        label="display_name"
                        :reduce="(val) => val.internal_name"
                        :options="operands"
                        v-model="item.operand"
                      />
                    </b-form-group>
                  </b-col>

                  <!-- Quantity -->
                  <b-col md="5" sm="6">
                    <b-form-group
                      :label="lang('t_value')"
                      label-for="t_value"
                      v-if="item.column == 'Kuyruk'"
                    >
                      <v-select
                        :options="queues"
                        v-model="item.value"
                        label="display_name"
                        :reduce="(val) => val.internal_name"
                      />
                    </b-form-group>
                    <b-form-group
                      :label="lang('t_value')"
                      label-for="t_value"
                      v-if="item.column && item.column.includes('_date')"
                    >
                      <flat-pickr
                        v-model="item.value"
                        class="form-control"
                        :config="{
                          enableTime: false,
                          dateFormat: 'Y-m-d',
                          allowInput: true,
                          time_24hr: true,
                        }"
                      />
                    </b-form-group>
                    <b-form-group
                      :label="lang('t_value')"
                      label-for="t_value"
                      v-if="item.column == 'finish_code'"
                    >
                      <v-select
                        :options="finish_codes"
                        v-model="item.value"
                        label="finish_code"
                        :reduce="(val) => val.finish_code"
                      />
                    </b-form-group>
                    <b-form-group
                      v-if="
                        item.column &&
                        !item.column.includes('_date') &&
                        !['Kuyruk', 'finish_code'].includes(item.column)
                      "
                      :label="lang('t_value')"
                      label-for="t_value"
                    >
                      <b-form-input
                        autofocus
                        id="t_value"
                        v-model="item.value"
                      />
                    </b-form-group>
                  </b-col>

                  <!-- Remove Button -->
                  <b-col md="1" sm="6">
                    <b-button
                      block
                      variant="flat-secondary"
                      class="btn-icon mt-2"
                      @click="removeItem(index)"
                      v-b-tooltip.hover.v-secondary
                      v-b-tooltip.hover.bottom="lang('t_deleteFilter')"
                    >
                      <feather-icon icon="TrashIcon" />
                    </b-button>
                  </b-col>
                  <div>
                    <hr />
                  </div>
                </b-row>
              </b-form>
            </div>
            <!-- <b-button block variant="primary" @click="repeateAgain">
              <feather-icon icon="PlusIcon" class="mr-25" />
              <span>{{ lang("t_addFilter") }}</span>
            </b-button>
            <b-button block variant="danger" @click="currentData.filter = []">
              <feather-icon icon="Trash2Icon" class="mr-25" />
              <span>{{ lang("t_clearFilter") }}</span>
            </b-button> -->
          </div>

          <!-- Form Actions -->
        </b-form>
      </validation-observer>
    </b-modal>
  </div>
</template>

<script>
import {
  BInputGroupPrepend,
  BInputGroup,
  BForm,
  BFormGroup,
  BFormInvalidFeedback,
  BFormCheckbox,
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BModal,
  VBTooltip,
  BFormRadio,
  BCardHeader,
  BCardBody,
  BCardTitle,
  BCardSubTitle,
  BCardFooter,
  BCardText,
  BFormSelect,
  BSpinner,
} from "bootstrap-vue";
import vSelect from "vue-select";
import store from "@/store";
import { ref, onUnmounted } from "@vue/composition-api";
import { avatarText } from "@core/utils/filter";
import useList from "./useList";
import storeModule from "../storeModule";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, alphaNum, email, password } from "@validations";
import formValidation from "@core/comp-functions/forms/form-validation";

import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import flatPickr from "vue-flatpickr-component";

export default {
  components: {
    // UsersListFilters,
    BSpinner,
    BCardText,
    BFormSelect,
    BCardBody,
    BCardTitle,
    BCardSubTitle,
    BCardFooter,
    BCardHeader,
    BInputGroupPrepend,
    BInputGroup,
    flatPickr,
    BForm,
    BFormGroup,
    BFormRadio,
    BFormInvalidFeedback,
    BFormCheckbox,
    ValidationProvider,
    ValidationObserver,
    BModal,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    vSelect,
  },
  computed: {
    items() {
      const start = (this.currentPage - 1) * this.perPage;
      const end = this.currentPage * this.perPage;
      return this.records.slice(start, end);
    },

  },
  data() {
    return {
      lang: _l(window.localStorage.getItem("language") || "tr"),
      isEdit: false,
      is_fullscreen: false,
      currentData: {
        _id: "",
        project: "",
        internal_name: "",
        display_name: "",
        mode: "dialer",
        status: true,
        auto_recycle: false,
        is_abandoned_campaign: false,
        filter: [],
      },
      trHeight: null,
      queues: [],
      finish_codes: [],
      records: [],
      tmp_columns: [],
      columns: [],
      projects: [],
      calling_modes: [
        { key: "ivn", value: "IVN" },
        { key: "dialer", value: "Dialer" },
      ],
      modal_record_details: false,
      tableColumns: [
        {
          key: "campaign_status",
          label: globalThis._lang("t_status"),
          sortable: true,
        },
        {
          key: "display_name",
          label: globalThis._lang("t_displayName"),
          sortable: true,
        },
        {
          key: "project",
          label: globalThis._lang("t_project"),
          sortable: true,
        },
        // {
        //   key: "create_date",
        //   label: globalThis._lang("t_date"),
        //   sortable: true,
        // },
        { key: "filter", label: globalThis._lang("t_filters"), sortable: true },
        {
          key: "actions",
          label: '',
          sortable: false,
          thStyle: { width: "5vh" },
        },
      ],
      column_labels: {
        status: "Durum",
        assigned_agent: "Agent",
        attempts: "Temas",
        campaign: "Kampanya",
        finish_code: "Son Sonuç Kodu",
        insert_date: "Arama Tarihi",
        action_date: "Aranacak Tarih",
        action_time: "Aranacak Zaman",
        create_date: "Yüklenme Tarihi",
        create_time: "Yüklenme Zamanı",
        ex_agent: "Ex Agent",
        assign_date: "Atama Tarihi",
        total_attempts: "Toplam Temas",
      },
      operands: [
        { internal_name: "=", display_name: globalThis._lang("t_equal") },
        { internal_name: "!=", display_name: globalThis._lang("t_notEqual") },
        { internal_name: "<", display_name: globalThis._lang("t_lessThan") },
        { internal_name: ">", display_name: globalThis._lang("t_greaterThan") },
        {
          internal_name: "<=",
          display_name: globalThis._lang("t_lessThanOrEqual"),
        },
        {
          internal_name: ">=",
          display_name: globalThis._lang("t_moreThanOrEqual"),
        },
      ],
    };
  },
  mounted: function () {
    this.queues = globalThis.queues;
    this.finish_codes = globalThis.finish_codes;
    this.finish_codes.push({ "finish_code": 'Abandoned' });
    this.finish_codes.unshift({ "finish_code": '-' });
    this.initTrHeight();
    this.get_records();
    // this.get_queues();
    this.get_project();
  },
  created() {
    window.addEventListener("resize", this.initTrHeight);
  },
  destroyed() {
    window.removeEventListener("resize", this.initTrHeight);
  },
  directives: {
    "b-tooltip": VBTooltip,
  },
  methods: {
    StartCampaign: async function (item) {
      await this.$http_in.get(`/dlr/v1/StartCampaign/${item.internal_name}`);
      await this.getCampaignStatus();
    },
    StopCampaign: async function (item) {
      await this.$http_in.get(`/dlr/v1/StopCampaign/${item.internal_name}`);
      let _cmp = this.records.find(e => e.internal_name == item.internal_name);
      _cmp.campaign_status = 'stopped';
      await this.getCampaignStatus();
    },
    RecycleCampaign: async function (item) {
      await this.StopCampaign(item);
      await this.$http_in.get(`/dlr/v1/RecycleCampaign/${item.internal_name}`);
      await this.getCampaignStatus();
    },
    getCampaignStatus: async function () {
      var response = (await this.$http_in.get(`/dlr/v1/RunningCampaigns`)).data;
      console.log("Campaign", response.RunningCampaigns);

      for (const campaign of this.records) {
        if (response.RunningCampaigns.includes(campaign.internal_name)) {
          campaign.campaign_status = 'active';
        } else {
          campaign.campaign_status = 'stopped';
        }
      }

      this.records = [...this.records];
    },
    trAddHeight(val) {
      // Add height to existing height
      // Usage: Where new element is append or more height is added (e.g. list append)

      /* Assumes:
       - Height is assigned and is `String`
       - Incoming value is valid number in `Number` or `String`
      */
      const heightValue = Number(
        this.trHeight.substring(0, this.trHeight.length - 2)
      );
      this.trHeight = `${heightValue + Number(val)}px`;
    },
    trTrimHeight(val) {
      // Remove height from existing height
      // Usage: Where new element is removed or height is remove (e.g. list pop/ele remove)

      /* Assumes:
       - Height is assigned and is `String`
       - Incoming value is valid number in `Number` or `String`
      */
      const heightValue = Number(
        this.trHeight.substring(0, this.trHeight.length - 2)
      );
      this.trHeight = `${heightValue - Number(val)}px`;
    },
    trSetHeight(val) {
      // Set height
      // Usage: Mostly for assigning initial value from mounted hook

      /* Assumes:
       - Height is not assigned and what to assign for add/remove operation
       - What to set height at something for odd usage
       - Incoming value is valid number in `Number` or `String`
      */
      if (val === null) this.trHeight = "auto";
      else this.trHeight = `${Number(val)}px`;
    },
    get_columns: async function () {
      this.tmp_columns = [];
      this.columns = [];
      console.log("currentData.project", this.currentData.project);
      var response = (
        await this.$http_in.get(
          `spv/v1/ProjectColumns/${this.currentData.project}_customers`
        )
      ).data;
      console.log("SmsColumns", response);
      //this.columns = response;
      this.tmp_columns =
        response[0].allkeys.filter(
          (e) =>
            ![
              "active",
              "phones",
              "sms",
              "extra_data",
              "_id",
              "TCKN_masked",
              "cycles",
              "notes",
              "products",
            ].includes(e)
        ) || [];
      for (const item of this.tmp_columns) {
        this.columns.push({
          internal_name: item,
          display_name: this.column_labels.hasOwnProperty(item)
            ? this.column_labels[item]
            : item,
        });
      }

      if (this.columns.length == 0) {
        this.columns.push(
          {
            internal_name: 'Kuyruk',
            display_name: this.column_labels.hasOwnProperty('Kuyruk')
              ? this.column_labels['Kuyruk']
              : 'Kuyruk',
          }
        )


      }
    },

    get_records: async function () {
      var response = (await this.$http_in.get(`spv/v1/Campaign`)).data;
      console.log("Campaign", response);
      this.records = response;
      await this.getCampaignStatus();

    },
    get_project: async function () {
      var response = (await this.$http_in.get(`spv/v1/Project`)).data;
      this.projects = response;
    },

    check_data() {
      if (
        this.currentData.display_name == "" ||
        [null, undefined, ""].includes(this.currentData.project)
      ) {
        return true;
      }
      return false;
    },

    add_records: async function () {
      if (this.check_data()) {
        this.$swal({
          title: globalThis._lang("t_warning"),
          text: globalThis._lang("t_pleaseDoNotLeaveAnyBlankFields"),
          icon: "warning",
          customClass: {
            confirmButton: "btn btn-primary",
          },
          buttonsStyling: false,
        });
        return;
      }

      var response;
      if (!this.isEdit) {
        console.log("this.currentData", this.currentData);
        response = (
          await this.$http_in.post(`spv/v1/Campaign`, {
            data: JSON.stringify(this.currentData),
          })
        ).data;
      } else {
        // if (this.stop_request()) {
        response = (
          await this.$http_in.put(`spv/v1/Campaign`, {
            data: JSON.stringify(this.currentData),
          })
        ).data;

        this.$http_in.get(
          `dlr/v1/RefreshCampaign/${this.currentData.internal_name}`
        );

        // }
      }

      if (response && response.acknowledged) {
        this.$swal({
          title: globalThis._lang("t_processSuccess"),
          text: globalThis._lang("t_hasBeenCreatedSuccessfully"),
          icon: "success",
          customClass: {
            confirmButton: "btn btn-primary",
          },
          buttonsStyling: false,
        });
      } else {
        this.$swal({
          title: globalThis._lang("t_error"),
          text: globalThis._lang("t_AProblemOccurredwhileProcessing"),
          icon: "error",
          customClass: {
            confirmButton: "btn btn-primary",
          },
          buttonsStyling: false,
        });
      }

      this.reset_record();
      this.get_records();
    },
    // stop_request() {
    //   this.$swal({
    //     title: "UYARI",
    //     text: `Kampanyanız şu anda aktif ise kampanya otomatik olarak durdurulacaktır, onaylıyor musunuz?`,
    //     icon: 'warning',
    //     allowOutsideClick: false,
    //     allowEscapeKey: false,
    //     closeOnClickOutside: false,
    //     showCancelButton: true,
    //     confirmButtonText: this.lang('t_yes'),
    //     cancelButtonText: this.lang('t_cancel'),
    //     customClass: {
    //       confirmButton: 'btn btn-primary',
    //       cancelButton: 'btn btn-outline-danger ml-1',

    //     },
    //     buttonsStyling: false,
    //   }).then(result => {
    //     if (result.value) {
    //       return true;
    //     } else {
    //       return false;
    //     }
    //   })
    // },
    delete_record: async function () {
      var response = (
        await this.$http_in.delete(`spv/v1/Campaign/${this.currentData._id}`)
      ).data;

      if (response != null || response != undefined) {
        this.$swal({
          icon: "success",
          title: this.lang("t_processSuccess"),
          text: this.lang("t_customersHaveBeenSuccessfullyDeleted"),
          confirmButtonText: this.lang("t_ok"),
          customClass: {
            confirmButton: "btn btn-success",
          },
        });
      }

      this.reset_record();
      this.get_records();
    },
    reset_record() {
      this.isEdit = false;
      this.currentData = {
        _id: "",
        project: "",
        internal_name: "",
        display_name: "",
        mode: "dialer",
        auto_recycle: false,
        is_abandoned_campaign: false,

        status: true,
        filter: [],
      };
    },
    repeateAgain() {
      this.currentData.filter.push({
        id: (this.nextTodoId += this.nextTodoId),
      });

      this.$nextTick(() => {
        this.trAddHeight(this.$refs.row[0].offsetHeight);
      });
    },
    removeItem(index) {
      this.currentData.filter.splice(index, 1);
      this.trTrimHeight(this.$refs.row[0].offsetHeight);
    },
    initTrHeight() {
      this.trSetHeight(null);
      this.$nextTick(() => { });
    },
    updateSelected(item) {
      this.currentData = item;
      console.log("item", item);
      this.isEdit = true;
      this.isAddNewUserSidebarActive = true;
      this.modal_record_details = true;
      this.get_columns();
    },
    confirmText(item) {
      this.currentData = item;
      this.$swal({
        title: this.lang("t_deleteRecord"),
        text: this.lang("t_areYouSureYouWantToDeleteTheSelectedRecord"),
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: this.lang("t_yes"),
        cancelButtonText: this.lang("t_cancel"),
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.delete_record();
        }
      });
    },
    deleteRecord: async function () {
      // Vue.$toast('Kayıt silme.');

      this.fetchData();
    },
  },
  setup() {
    const USER_APP_STORE_MODULE_NAME = "app-user";
    // const USER_APP_STORE_MODULE_NAME = 'app-user'
    const toast = Vue.$toast;

    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME))
      store.registerModule(USER_APP_STORE_MODULE_NAME, storeModule);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME))
        store.unregisterModule(USER_APP_STORE_MODULE_NAME);
    });

    const resetuserData = () => {
      currentData.value = JSON.parse(JSON.stringify(blankUserData));
    };

    const {
      refFormObserver,

      resetForm,
    } = formValidation(resetuserData);

    const isAddNewUserSidebarActive = ref(false);

    const planOptions = [
      { label: "Basic", value: "basic" },
      { label: "Company", value: "company" },
      { label: "Enterprise", value: "enterprise" },
      { label: "Team", value: "team" },
    ];

    const statusOptions = [
      { label: "Pending", value: "pending" },
      { label: "Active", value: "active" },
      { label: "Inactive", value: "inactive" },
    ];

    var {
      fetchData,
      tableColumns,
      perPage,
      currentPage,
      totalUsers,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refUserListTable,
      refetchData,

      // UI
      resolveUserRoleVariant,
      resolveUserRoleIcon,
      resolveUserStatusVariant,

      // Extra Filters
      roleFilter,
      planFilter,
      statusFilter,
    } = useList();

    return {
      // Sidebar
      isAddNewUserSidebarActive,

      fetchData,
      tableColumns,
      perPage,
      currentPage,
      totalUsers,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refUserListTable,
      refetchData,

      // Filter
      avatarText,

      // UI
      resolveUserRoleVariant,
      resolveUserRoleIcon,
      resolveUserStatusVariant,

      projects,
      planOptions,
      statusOptions,

      // Extra Filters
      roleFilter,
      planFilter,
      statusFilter,

      toast,
      refFormObserver,

      resetForm,
    };
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
