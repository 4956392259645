<template>
  <div v-if="true">
    <b-custom-spinner :is_progress="is_progress"></b-custom-spinner>

    <b-row>
      <b-col md="12" sm="12">
        <b-card no-body>
          <b-card-body :class="['d-flex', 'align-items-center']">
            <v-select
              style="width: 100%"
              v-model="selected_campaign"
              @input="update_settings"
              :options="campaigns"
              :placeholder="lang('t_campaign')"
              :reduce="(option) => option"
              label="display_name"
              :clearable="false"
              :loading="selected_campaign.status == 'recycling'"
            >
              <template #spinner="{ loading }">
                <div
                  v-if="loading"
                  style="border-left-color: rgba(115, 102, 240, 1)"
                  class="vs__spinner mr-50"
                >
                  test
                </div>
                <b-icon
                  v-if="selected_campaign.status == 'stopped'"
                  variant="danger"
                  scale="1"
                  icon="circle-fill"
                  class="mr-50"
                ></b-icon>
                <b-spinner
                  v-if="selected_campaign.status == 'running'"
                  :variant="'success'"
                  small
                  type="grow"
                  class="mr-50"
                />
              </template>
            </v-select>

            <b-button
              class="px-50 py-50 mr-50 ml-2"
              v-b-tooltip.hover.v-secondary
              v-b-tooltip.hover.top="lang('t_settings')"
              @click="settings_modal = true"
              :disabled="
                selected_campaign.status == '' ||
                selected_campaign.status == 'recycling'
              "
              size="sm"
              variant="flat-secondary"
            >
              <b-icon scale="1.2" :icon="'gear'"></b-icon>
            </b-button>
            <b-button
              v-b-tooltip.hover.v-secondary
              v-b-tooltip.hover.top="
                selected_campaign.status == 'running'
                  ? lang('t_stop')
                  : lang('t_start')
              "
              class="px-50 py-50"
              @click="
                changeCampaignStatus(
                  selected_campaign.status == 'running' ? 'stopped' : 'running'
                )
              "
              :disabled="
                selected_campaign.status == '' ||
                selected_campaign.status == 'recycling'
              "
              size="sm"
              variant="flat-secondary"
            >
              <b-icon
                scale="1.2"
                :icon="selected_campaign.status == 'running' ? 'pause' : 'play'"
              ></b-icon>
            </b-button>
            <b-button
              v-b-tooltip.hover.top="lang('t_recycle')"
              v-b-tooltip.hover.v-secondary
              class="px-50 py-50 ml-50"
              @click="changeCampaignStatus('recycling')"
              :disabled="
                selected_campaign.status == '' ||
                selected_campaign.status == 'running' ||
                selected_campaign.status == 'recycling'
              "
              size="sm"
              variant="flat-secondary"
            >
              <b-icon scale="1.2" icon="arrow-repeat"></b-icon>
            </b-button>
            <b-dropdown
              dropleft
              boundary="viewport"
              size="sm"
              variant="flat-secondary"
              no-caret
              :right="$store.state.appConfig.isRTL"
              toggle-class="px-50 py-50 ml-50"
            >
              <template #button-content>
                <i class="bi bi-three-dots-vertical font-medium-1"></i>
              </template>

              <b-dropdown-item
                @click="clearSchCallTimes"
                v-if="
                  other_permission.includes('dialer_reset_schedule_calls') &&
                  selected_campaign.hasOwnProperty('internal_name')
                "
              >
                <span class="align-middle ml-50">{{
                  lang("t_resetScheduledCalls")
                }}</span>
              </b-dropdown-item>
              <b-dropdown-item
                @click="clearDailyAttempts"
                v-if="
                  other_permission.includes('dialer_reset_daily_attempts') &&
                  selected_campaign.hasOwnProperty('internal_name')
                "
              >
                <span class="align-middle ml-50">{{
                  lang("t_resetDailyAttempts")
                }}</span>
              </b-dropdown-item>
              <b-dropdown-item
                @click="clearTotalAttempts"
                v-if="
                  other_permission.includes('dialer_reset_total_attempts') &&
                  selected_campaign.hasOwnProperty('internal_name')
                "
              >
                <span class="align-middle ml-50">{{
                  lang("t_resetTotalAttempts")
                }}</span>
              </b-dropdown-item>
            </b-dropdown>
          </b-card-body>
        </b-card>
      </b-col>
      <b-col md="12" sm="12">
        <b-card no-body>
          <b-card-body>
            <b-row>
              <b-col class="d-flex align-items-center justify-content-between">
                <b-media
                  v-for="(key, i) in Object.keys(call_summary)"
                  :key="i"
                  v-b-tooltip.hover.v-secondary
                  v-b-tooltip.hover.bottom="
                    spokeInfoObject[key].descriptionText
                  "
                  class="mt-auto"
                  no-body
                >
                  <b-media-aside>
                    <b-avatar
                      size="3rem"
                      rounded="lg"
                      :variant="spokeInfoObject[key].avatarColor"
                    >
                      <!-- <b-icon scale="2rem" icon="telephone-plus"></b-icon> -->
                      <i
                        class="font-medium-3"
                        :class="spokeInfoObject[key].iconClass"
                      ></i>
                    </b-avatar>
                  </b-media-aside>
                  <b-media-body>
                    <h6 class="font-weight-bolder mb-0 text-truncate">
                      {{ call_summary[key] }}
                    </h6>
                    <b-card-text
                      class="font-small-4 text-muted mb-0 text-truncate"
                      style="max-width: 8rem"
                    >
                      {{ spokeInfoObject[key].displayName }}
                    </b-card-text>
                  </b-media-body>
                </b-media>
              </b-col>
            </b-row>
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="5">
        <b-card style="height: 26.5rem; overflow: auto">
          <b-row>
            <b-col cols="3">
              <b-tabs
                v-model="tabIndex"
                vertical
                pills
                active-nav-item-class="bg-light-primary"
              >
                <b-tab
                  @click="tabIndex = 0"
                  :title-item-class="tabIndex == 0 ? activeClass : passiveClass"
                >
                  <template #title>
                    <div class="my-50" style="height: 4rem; width: 4rem">
                      <b-row>
                        <b-col class="text-center">
                          <b-avatar
                            rounded="lg"
                            class="text-center"
                            :variant="
                              tabIndex == 0
                                ? 'light-primary'
                                : 'light-secondary'
                            "
                          >
                            <i
                              :class="'bi bi-clipboard-data font-medium-1 pl-50'"
                            ></i>
                          </b-avatar>
                        </b-col>
                      </b-row>

                      <b-row>
                        <b-col class="text-center mt-50">
                          <span>{{ lang("t_project") }}</span>
                        </b-col>
                      </b-row>
                    </div>
                  </template>
                </b-tab>
                <b-tab
                  @click="tabIndex = 1"
                  :title-item-class="tabIndex == 1 ? activeClass : passiveClass"
                >
                  <template #title>
                    <div class="my-50" style="height: 4rem; width: 4rem">
                      <b-row>
                        <b-col class="text-center">
                          <b-avatar
                            rounded="lg"
                            class="text-center"
                            :variant="
                              tabIndex == 1
                                ? 'light-primary'
                                : 'light-secondary'
                            "
                          >
                            <i
                              :class="'bi bi-ui-checks font-medium-1 pl-50'"
                            ></i>
                          </b-avatar>
                        </b-col>
                      </b-row>

                      <b-row>
                        <b-col class="text-center mt-50">
                          <span>{{ lang("t_detail") }}</span>
                        </b-col>
                      </b-row>
                    </div>
                  </template>
                </b-tab>
                <b-tab
                  :title-item-class="tabIndex == 2 ? activeClass : passiveClass"
                >
                  <template #title>
                    <div class="my-50" style="height: 4rem; width: 4rem">
                      <b-row>
                        <b-col class="text-center">
                          <b-avatar
                            rounded="lg"
                            class="text-center"
                            :variant="
                              tabIndex == 2
                                ? 'light-primary'
                                : 'light-secondary'
                            "
                          >
                            <i
                              :class="'bi bi-bar-chart font-medium-1 pl-50'"
                            ></i>
                          </b-avatar>
                        </b-col>
                      </b-row>

                      <b-row>
                        <b-col class="text-center mt-50">
                          <span>{{ lang("t_call") }}</span>
                        </b-col>
                      </b-row>
                    </div>
                  </template>
                </b-tab>
              </b-tabs>
            </b-col>
            <b-col cols="9">
              <div v-show="tabIndex == 0">
                <b-media
                  class="mb-1 d-flex align-items-center"
                  no-body
                  v-for="(item, i) in agent_data"
                  :key="i"
                >
                  <b-media-aside>
                    <b-avatar rounded="lg" :variant="'light-secondary'">
                      <i :class="item.iconClass"></i>
                    </b-avatar>
                  </b-media-aside>

                  <b-media-body class="d-flex align-items-center mr-2">
                    <h5
                      class="font-weight-bolder mb-0 text-truncate animated-text"
                    >
                      {{ item.key }}
                    </h5>
                    <h5 class="ml-auto animated-text mb-0">
                      {{ item.value }}
                      <!-- <b-icon variant="success" icon="arrow-up-short"></b-icon> -->
                    </h5>
                  </b-media-body>
                </b-media>
              </div>
              <div v-show="tabIndex == 1">
                <b-media
                  class="mb-1 d-flex align-items-center"
                  no-body
                  v-for="(item, i) in campaign_data"
                  :key="i"
                >
                  <b-media-aside>
                    <b-avatar rounded="lg" :variant="'light-secondary'">
                      <i :class="item.iconClass"></i>
                    </b-avatar>
                  </b-media-aside>

                  <b-media-body class="d-flex align-items-center mr-2">
                    <h5
                      class="font-weight-bolder mb-0 text-truncate animated-text"
                    >
                      {{ item.key }}
                    </h5>
                    <h5 class="ml-auto animated-text mb-0">
                      {{ item.value }}
                      <b-icon variant="success" icon="arrow-up-short"></b-icon>
                    </h5>
                  </b-media-body>
                </b-media>
              </div>
              <div v-show="tabIndex == 2">
                <b-media
                  class="mb-1 d-flex align-items-center"
                  no-body
                  v-for="(item, i) in call_data"
                  :key="i"
                >
                  <b-media-aside>
                    <b-avatar rounded="lg" :variant="'light-secondary'">
                      <i :class="item.iconClass"></i>
                    </b-avatar>
                  </b-media-aside>

                  <b-media-body class="d-flex align-items-center mr-2">
                    <h5
                      class="font-weight-bolder mb-0 text-truncate animated-text"
                    >
                      {{ item.key }}
                    </h5>
                    <h5 class="ml-auto animated-text mb-0">
                      {{ item.value }}
                      <b-icon variant="success" icon="arrow-up-short"></b-icon>
                    </h5>
                  </b-media-body>
                </b-media>
              </div>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
      <b-col cols="7">
        <voyce-table-without-card
          :card_height="'26.5rem'"
          :title="'t_finishCodes'"
          :sub_title="'Yapılan tüm çağrılar'"
          :data="fc_data"
          :fields="fc_fields"
          :sortDesc="true"
          :extra="{}"
        ></voyce-table-without-card>
      </b-col>
    </b-row>

    <b-row>
      <b-col>
        <voyce-table-without-card
          :no_header="true"
          :card_height="'30rem'"
          :title="'t_finishCodes'"
          :sub_title="'Yapılan tüm çağrılar'"
          :data="active_calls"
          :fields="active_call_fields"
          :sortDesc="true"
          :extra="{}"
        ></voyce-table-without-card>
      </b-col>
    </b-row>

    <b-modal
      v-if="selected_campaign && selected_campaign.status != ''"
      size="lg"
      scrollable
      no-close-on-esc
      no-close-on-backdrop
      v-model="settings_modal"
      :title="lang('t_settings')"
      :ok-title="lang('t_save')"
      :cancel-title="lang('t_close')"
      @ok="save_campaign_settings"
    >
      <b-tabs
        pills
        active-nav-item-class="bg-light-primary border-0 shadow-none cursor-pointer"
      >
        <b-tab active style="height: 645px">
          <template #title>
            <span>{{ lang("t_callStrategy") }}</span>
          </template>

          <b-card-text>
            <b-row v-if="selected_campaign.mode == 'dialer'">
              <b-col>
                <!-- <b-form-group
                  :label="lang('t_priority')"
                  label-for="priority"
                  description="Kampanyalar arası çağrı önceliği"
                >
                  <b-form-input
                    id="priority"
                    :placeholder="lang('t_priority')"
                    type="number"
                  />
                </b-form-group> -->
                <b-form-group
                  :label="lang('t_maxAbandonRate')"
                  label-for="max_abandon_rate"
                  :description="
                    lang('t_maximumRateOfCallsThatAreAbandonedFromTheQueue')
                  "
                >
                  <b-form-input
                    v-model="selected_campaign.settings.abandon_rate"
                    id="max_abandon_rate"
                    :placeholder="lang('t_maxAbandonRate')"
                    type="number"
                  />
                </b-form-group>
              </b-col>
              <b-col>
                <b-form-group
                  :label="lang('t_callsPerAgent')"
                  label-for="call_per_agent"
                  :description="lang('t_callsPerAvailableAgents')"
                >
                  <b-form-input
                    v-model="selected_campaign.settings.call_per_agent"
                    id="call_per_agent"
                    :placeholder="lang('t_callsPerAgent')"
                    type="number"
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <b-form-group
                  :label="lang('t_maxCall')"
                  label-for="max_calls"
                  :description="
                    lang('t_maximumAmountOfCallsThisCampaignCanStart')
                  "
                >
                  <b-form-input
                    v-model="selected_campaign.settings.max_call"
                    id="max_calls"
                    :placeholder="lang('t_maxCall')"
                    type="number"
                  />
                </b-form-group>
              </b-col>
              <b-col>
                <b-form-group
                  :label="lang('t_statisticsPeriod')"
                  label-for="s_period"
                  :description="
                    lang('t_refreshRateOfThisCampaignsStatisticsGraphs')
                  "
                >
                  <b-form-input
                    v-model="selected_campaign.settings.statistics_period"
                    id="s_period"
                    :placeholder="lang('t_statisticsPeriod')"
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <b-form-group
                  :label="lang('t_callingMethod')"
                  label-for="line_group"
                  :description="lang('t_whichLineGroupToMakeCallsFrom')"
                >
                  <!-- <b-form-input
                    id="line_group"
                    :placeholder="lang('t_callingMethod')"
                    type="number"
                  /> -->
                  <v-select
                    v-model="selected_campaign.settings.line_group"
                    :options="lg"
                    :placeholder="lang('t_campaign')"
                    :reduce="(option) => option"
                    label="display_name"
                  >
                    <template #option="{ display_name, type }">
                      <div class="d-flex align-items-center"></div>
                      <span
                        v-if="type == 'line_group'"
                        class=""
                        style="margin: 0"
                      >
                        {{ display_name }}
                      </span>
                      <span v-else class="font-weight-bolder" style="margin: 0">
                        {{ display_name }}
                      </span>
                      <small
                        v-if="type == 'dialer_precall'"
                        class="text-muted ml-1"
                      >
                        {{ lang(`Precall`) }}</small
                      >
                    </template>
                  </v-select>
                </b-form-group>
              </b-col>
              <b-col>
                <b-form-group
                  :label="lang('t_noAnswerTimeout')"
                  label-for="no_answer_time_out"
                  :description="lang('t_maximumRingingTime')"
                >
                  <b-form-input
                    v-model="selected_campaign.settings.timeout_second"
                    id="no_answer_time_out"
                    :placeholder="lang('t_noAnswerTimeout')"
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <b-form-group
                  :label="lang('t_maxCycles')"
                  label-for="max_cycles"
                  :description="
                    lang(
                      't_amountOfRoundsToCallPeopleWhoHaventBeenContactedYet'
                    )
                  "
                >
                  <b-form-input
                    v-model="selected_campaign.settings.max_recyle"
                    id="max_cycles"
                    :placeholder="'1'"
                    type="number"
                  />
                </b-form-group>
              </b-col>
              <b-col>
                <b-form-group
                  :label="lang('t_recallDelayMins')"
                  label-for="recall_delay_mins"
                  :description="
                    lang('t_minimumAmountOfMinutesToRecallAPhoneNumber')
                  "
                >
                  <b-form-input
                    v-model="selected_campaign.settings.next_call_time"
                    id="no_answer_time_out"
                    :placeholder="'20'"
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <b-form-group
                  :label="lang('t_dailyMaxPhoneTouch')"
                  label-for="max_phone_attempts"
                  :description="lang('t_maximumCallAmountPerPhoneNumberPerDay')"
                >
                  <b-form-input
                    v-model="selected_campaign.settings.max_phone_touch"
                    id="max_phone_attempts"
                    :placeholder="'5'"
                    type="number"
                  />
                </b-form-group>
              </b-col>
              <b-col>
                <b-form-group
                  :label="lang('t_dailyMaxContactTouch')"
                  label-for="max_contact_attempts"
                  :description="lang('t_maximumCallAmountPerContactPerDay')"
                >
                  <b-form-input
                    v-model="selected_campaign.settings.max_contact_touch"
                    id="max_contact_attempts"
                    :placeholder="lang('t_dailyMaxContactTouch')"
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <b-form-group
                  :label="lang('t_totalMaxPhoneTouch')"
                  label-for="max_phone_attempts"
                  :description="lang('t_maximumCallAmountPerPhoneNumber')"
                >
                  <b-form-input
                    v-model="
                      selected_campaign.settings.max_phone_total_attempts
                    "
                    :placeholder="'12'"
                    id="max_phone_attempts_total"
                    type="number"
                  />
                </b-form-group>
              </b-col>
              <b-col>
                <b-form-group
                  :label="lang('t_totalMaxContactTouch')"
                  label-for="max_contact_attempts_total"
                  :description="lang('t_maximumCallAmountPerContact')"
                >
                  <b-form-input
                    v-model="
                      selected_campaign.settings.max_contact_total_attempts
                    "
                    id="max_contact_attempts"
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <b-form-checkbox
                  v-model="selected_campaign.settings.just_call_mobile_phone"
                  >{{ lang("t_justCallMobilePhone") }}</b-form-checkbox
                >
              </b-col>
            </b-row>
          </b-card-text>
        </b-tab>
        <b-tab style="height: 645px">
          <template #title>
            <span>{{ lang("t_sortCriteria") }}</span>
          </template>

          <b-card-text>
            <div class="mt-1">
              <b-row>
                <!-- people group 1 -->
                <b-col md="6" class="text-center">
                  <b-card
                    class="mb-0"
                    style="height: 45rem; overflow-y: auto"
                    :title="lang('t_columns')"
                  >
                    <draggable
                      style="min-height: 500px"
                      :list="list1"
                      tag="ul"
                      group="people"
                      class="list-group list-group-flush cursor-move mx-0"
                    >
                      <b-list-group-item
                        v-for="(listItem, index) in list1"
                        :key="index"
                        tag="li"
                        class="border-primary rounded mt-50 py-50 px-1"
                      >
                        <div class="d-flex">
                          <div class="pl-25">
                            <b-card-text class="mb-0 font-weight-bold">
                              {{ listItem.display_name }}
                            </b-card-text>
                          </div>
                        </div>
                      </b-list-group-item>
                    </draggable>
                  </b-card>

                  <!-- draggable -->
                </b-col>

                <!-- people group 2 -->
                <b-col md="6" class="mt-sm-2 mt-md-0 text-center">
                  <b-card
                    class="mb-0"
                    style="height: 45rem; overflow-y: auto"
                    :title="lang('t_sort')"
                  >
                    <!-- draggable -->
                    <draggable
                      style="min-height: 40rem"
                      :list="list2"
                      tag="ul"
                      group="people"
                      class="list-group list-group-flush cursor-move mx-0"
                    >
                      <b-list-group-item
                        v-for="(listItem, index) in list2"
                        :key="index"
                        tag="li"
                        class="border-primary rounded mt-50 py-50 px-1"
                      >
                        <div>
                          <div class="pl-50 mx-75">
                            <b-card-text class="mb-0 font-weight-bold">
                              <b-row align-h="between">
                                <span> {{ listItem.display_name }} </span>
                                <b-form-checkbox
                                  v-model="listItem.desc"
                                  size="md"
                                  class="custom-control-primary"
                                  name="check-button"
                                  switch
                                >
                                  <span class="switch-icon-left">BK </span>
                                  <span class="switch-icon-right"> KB </span>
                                </b-form-checkbox>
                              </b-row>
                            </b-card-text>
                          </div>
                        </div>
                      </b-list-group-item>
                    </draggable>
                  </b-card>
                </b-col>
              </b-row>
            </div>
          </b-card-text>
        </b-tab>
        <b-tab style="height: 645px">
          <template #title>
            <span>{{ lang("t_settings") }}</span>
          </template>

          <b-card-text>
            <b-row>
              <b-col>
                <b-form-group
                  :label="lang('t_timeCondition')"
                  label-for="live-contact"
                >
                  <v-select
                    v-model="selected_campaign.settings.time_condition"
                    :options="tc"
                    :placeholder="lang('t_pleaseSelectTimeCondition')"
                    label="display_name"
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <b-form-group
                  :label="lang('t_liveContact')"
                  label-for="live-contact"
                >
                  <v-select
                    v-model="selected_campaign.settings.live_contact.category"
                    :options="ivr_type_categories"
                    :reduce="(val) => val.internal_name"
                    label="display_name"
                    :placeholder="lang('t_liveContact')"
                  />
                </b-form-group>
              </b-col>
              <b-col>
                <b-form-group
                  :label="lang('t_noAvailableAgent')"
                  label-for="no-available-agent"
                >
                  <v-select
                    v-model="selected_campaign.settings.no_idle_agent.category"
                    :options="ivr_type_categories"
                    :reduce="(val) => val.internal_name"
                    label="display_name"
                    :placeholder="lang('t_noAvailableAgent')"
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <b-form-group
                  v-if="
                    selected_campaign.settings.live_contact.category ==
                    'transfer_ivr'
                  "
                  :label="lang('t_liveContacttIVR')"
                  label-for="live-contact-ivr"
                >
                  <v-select
                    @input="set_current_queue"
                    v-model="selected_campaign.settings.live_contact.target"
                    :options="ivr.filter((e) => e.type != 'queue')"
                    :reduce="(val) => val.internal_name"
                    label="display_name"
                    :placeholder="lang('t_liveContacttIVR')"
                  />
                </b-form-group>
                <b-form-group
                  v-if="
                    selected_campaign.settings.live_contact.category ==
                    'transfer_queue'
                  "
                  :label="lang('t_liveContacttIVR')"
                  label-for="live-contact-ivr"
                >
                  <v-select
                    @input="set_current_queue"
                    v-model="selected_campaign.settings.live_contact.target"
                    :options="ivr.filter((e) => e.type == 'queue')"
                    :reduce="(val) => val.internal_name"
                    label="display_name"
                    :placeholder="lang('t_liveContacttIVR')"
                  />
                </b-form-group>
              </b-col>
              <b-col>
                <b-form-group
                  v-if="
                    selected_campaign.settings.live_contact.category ==
                    'transfer_ivr'
                  "
                  :label="lang('t_noAvailableAgentIVR')"
                  label-for="no-available-agent-ivr"
                >
                  <v-select
                    @input="set_current_queue"
                    v-model="selected_campaign.settings.no_idle_agent.target"
                    :options="ivr.filter((e) => e.type != 'queue')"
                    :reduce="(val) => val.internal_name"
                    label="display_name"
                    :placeholder="lang('t_liveContacttIVR')"
                  />
                </b-form-group>
                <b-form-group
                  v-if="
                    selected_campaign.settings.live_contact.category ==
                    'transfer_queue'
                  "
                  :label="lang('t_noAvailableAgentIVR')"
                  label-for="no-available-agent-ivr"
                >
                  <v-select
                    @input="set_current_queue"
                    v-model="selected_campaign.settings.no_idle_agent.target"
                    :options="ivr.filter((e) => e.type == 'queue')"
                    :reduce="(val) => val.internal_name"
                    label="display_name"
                    :placeholder="lang('t_liveContacttIVR')"
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <b-form-group label-for="dont_connect_machines">
                  <b-form-checkbox
                    v-b-tooltip.hover.v-secondary
                    v-b-tooltip.hover.bottom="
                      lang('t_doNotConnectMachineAnsweredCalls')
                    "
                    v-model="selected_campaign.settings.machine_detection"
                    class="custom-control-primary"
                    name="dont_connect_machines"
                  >
                    {{ lang("t_dontConnectMachines") }}
                  </b-form-checkbox>
                </b-form-group>
              </b-col>
            </b-row>
          </b-card-text>
        </b-tab>
      </b-tabs>
    </b-modal>
  </div>

  <b-container v-else fluid class="px-0">
    <!-- <b-custom-spinner :is_progress="is_progress"></b-custom-spinner> -->

    <b-card class="mb-1 px-0 mx-0">
      <b-row class="mx-0 px-0">
        <b-col cols="4" sm="8" md="4"> </b-col>
        <b-col sm="4" md="8">
          <b-button
            @click="
              changeCampaignStatus(
                selected_campaign.status == 'running' ? 'stopped' : 'running'
              )
            "
            :disabled="
              selected_campaign.status == '' ||
              selected_campaign.status == 'recycling'
            "
            variant="primary"
            class="btn-icon rounded-circle mr-50"
          >
            <feather-icon
              :icon="
                selected_campaign.status == 'running' ? 'PauseIcon' : 'PlayIcon'
              "
            />
          </b-button>
          <b-button
            @click="changeCampaignStatus('recycling')"
            :disabled="
              selected_campaign.status == '' ||
              selected_campaign.status == 'running' ||
              selected_campaign.status == 'recycling'
            "
            variant="primary"
            class="btn-icon rounded-circle"
          >
            <feather-icon icon="RefreshCwIcon" />
          </b-button>
          <b-button
            v-if="
              other_permission.includes('dialer_reset_schedule_calls') &&
              selected_campaign.hasOwnProperty('internal_name')
            "
            v-b-tooltip.hover.v-warning
            v-b-tooltip.hover.top="lang('t_resetScheduledCalls')"
            @click="clearSchCallTimes"
            variant="warning"
            class="btn-icon rounded-circle float-right ml-50"
          >
            <feather-icon icon="ClockIcon" />
          </b-button>
          <b-button
            v-if="
              other_permission.includes('dialer_reset_daily_attempts') &&
              selected_campaign.hasOwnProperty('internal_name')
            "
            v-b-tooltip.hover.v-warning
            v-b-tooltip.hover.top="lang('t_resetDailyAttempts')"
            @click="clearDailyAttempts"
            variant="warning"
            class="btn-icon rounded-circle float-right ml-50"
          >
            <feather-icon icon="ShieldOffIcon" />
          </b-button>
          <b-button
            v-if="
              other_permission.includes('dialer_reset_total_attempts') &&
              selected_campaign.hasOwnProperty('internal_name')
            "
            v-b-tooltip.hover.v-warning
            v-b-tooltip.hover.top="lang('t_resetTotalAttempts')"
            @click="clearTotalAttempts"
            variant="warning"
            class="btn-icon rounded-circle float-right ml-50"
          >
            <feather-icon icon="UnlockIcon" />
          </b-button>
        </b-col>
      </b-row>
    </b-card>
    <b-row class="mx-0 px-0">
      <b-col sm="12" md="4">
        <b-card style="height: 25vh" class="card-browser-states mt-1" no-body>
          <!-- <b-card-header class="pa-0">
            <div>
              <b-card-title>Browser States</b-card-title>
            </div>
          </b-card-header> -->

          <!-- body -->
          <b-card-body>
            <div
              v-for="(item, index) in agent_data"
              :key="index"
              class="browser-states mt-50"
            >
              <b-media no-body>
                <feather-icon class="mr-1" icon="SlackIcon" />
                <h6 class="align-self-center my-auto">
                  {{ item.key }}
                </h6>
              </b-media>
              <div class="d-flex align-items-center">
                <span
                  class="font-weight-bold text-body-heading mr-1"
                  style="
                    display: inline-block;
                    width: 7vw;
                    white-space: nowrap;
                    overflow: hidden !important;
                    text-overflow: ellipsis;
                  "
                  >{{ item.value }}</span
                >
              </div>
            </div>
          </b-card-body>
          <!--/ body -->
        </b-card>
      </b-col>
      <b-col sm="12" md="4">
        <b-card style="height: 25vh" class="card-browser-states mt-1" no-body>
          <!-- <b-card-header class="pa-0">
            <div>
              <b-card-title>Browser States</b-card-title>
            </div>
          </b-card-header> -->

          <!-- body -->
          <b-card-body>
            <div
              v-for="(item, index) in campaign_data"
              :key="index"
              class="browser-states mt-50"
            >
              <b-media no-body>
                <feather-icon class="mr-1" icon="SlackIcon" />
                <h6 class="align-self-center my-auto">
                  {{ item.key }}
                </h6>
              </b-media>
              <div class="d-flex align-items-center">
                <span
                  class="font-weight-bold text-body-heading mr-1"
                  style="
                    display: inline-block;
                    width: 7vw;
                    white-space: nowrap;
                    overflow: hidden !important;
                    text-overflow: ellipsis;
                  "
                  v-b-tooltip.hover.v-secondary
                  v-b-tooltip.hover.top="item.value"
                  >{{ item.value }}</span
                >
              </div>
            </div>
          </b-card-body>
          <!--/ body -->
        </b-card>
      </b-col>
      <b-col sm="12" md="4">
        <b-card style="height: 25vh" class="card-browser-states mt-1" no-body>
          <!-- <b-card-header class="pa-0">
            <div>
              <b-card-title>Browser States</b-card-title>
            </div>
          </b-card-header> -->

          <!-- body -->
          <b-card-body>
            <div
              v-for="(item, index) in call_data"
              :key="index"
              class="browser-states mt-50"
            >
              <b-media no-body>
                <feather-icon class="mr-1" icon="SlackIcon" />
                <h6 class="align-self-center my-auto">
                  {{ item.key }}
                </h6>
              </b-media>
              <div class="d-flex align-items-center">
                <b-badge
                  variant="primary"
                  class="badge-glow text-center"
                  style="width: 5em"
                >
                  <span
                    class="font-weight-bold text-body-heading text-white m-auto"
                    >{{ item.value }}</span
                  >
                </b-badge>
              </div>
            </div>
          </b-card-body>
          <!--/ body -->
        </b-card>
      </b-col>
    </b-row>
    <b-row class="mx-0">
      <b-col sm="12" md="5">
        <b-card style="height: 36vh; overflow-y: auto" no-body>
          <!-- <chartjs-component-bar-chart
            :height="300"
            :data="chartjsData.latestBarChart.data"
            :options="chartjsData.latestBarChart.options"
            :key="custom_key"
          /> -->
          <b-table
            style="min-height: 26vh; overflow-y: auto"
            small
            :fields="fc_fields"
            :items="fc_data"
            responsive="sm"
          >
            <template #cell(rate)="data">
              <b-progress
                :value="data.value"
                :max="total_finish_code_counts"
                :variant="
                  parseInt((data.value / total_finish_code_counts) * 100) > 40
                    ? 'success'
                    : 'warning'
                "
                striped
              />
            </template>

            <template #cell(category)="data">
              <b-badge pill :variant="fc_category_colors[data.value]">
                {{ data.value }}
              </b-badge>
            </template>
          </b-table>
          <b-row class="border-top float-bottom mx-0">
            <b-col
              class="border-right"
              v-b-tooltip.hover.v-primary
              v-b-tooltip.hover.top="lang('t_totalCallInfo')"
            >
              <center class="py-1">
                <span style="font-size: 12px" class="text-secondary">{{
                  lang("t_total")
                }}</span>
                <h6>{{ call_summary.TotalCall }}</h6>
              </center>
            </b-col>
            <b-col
              class="border-right"
              v-b-tooltip.hover.v-primary
              v-b-tooltip.hover.top="lang('t_spokenCallInfo')"
            >
              <center class="py-1">
                <span style="font-size: 12px" class="text-secondary">{{
                  lang("t_spokenCall")
                }}</span>
                <h6>{{ call_summary.SpokenCall }}</h6>
              </center>
            </b-col>
            <b-col
              class="border-right"
              v-b-tooltip.hover.v-primary
              v-b-tooltip.hover.top="lang('t_spokenRateInfo')"
            >
              <center class="py-1">
                <span style="font-size: 12px" class="text-secondary">{{
                  lang("t_spokenCallRate")
                }}</span>
                <h6>{{ call_summary.SpokenRate }}</h6>
              </center>
            </b-col>
            <b-col
              class="border-right"
              v-b-tooltip.hover.v-primary
              v-b-tooltip.hover.top="lang('t_noSpokenCallInfo')"
            >
              <center class="py-1">
                <span style="font-size: 12px" class="text-secondary">{{
                  lang("t_nonSpokenCall")
                }}</span>
                <h6>{{ call_summary.NoSpokenCall }}</h6>
              </center>
            </b-col>

            <b-col
              class="border-right"
              v-b-tooltip.hover.v-primary
              v-b-tooltip.hover.top="lang('t_noAttemptsCallInfo')"
            >
              <center class="py-1">
                <span style="font-size: 12px" class="text-secondary">{{
                  lang("t_nAttemptsCall")
                }}</span>
                <h6>{{ call_summary.NoAttemptsCall }}</h6>
              </center>
            </b-col>
            <b-col
              class="border-right"
              v-b-tooltip.hover.v-primary
              v-b-tooltip.hover.top="lang('t_attemptsRateInfo')"
            >
              <center class="py-1">
                <span style="font-size: 12px" class="text-secondary">{{
                  lang("t_attemptsRate")
                }}</span>
                <h6>{{ call_summary.AttemptsRate }}</h6>
              </center>
            </b-col>

            <b-col
              v-b-tooltip.hover.v-primary
              v-b-tooltip.hover.top="lang('t_reachRateInfo')"
            >
              <center class="py-1">
                <span style="font-size: 12px" class="text-secondary">{{
                  lang("t_reachRate")
                }}</span>
                <h6>{{ call_summary.ReachRate }}</h6>
              </center>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
      <b-col sm="12" md="7">
        <b-card style="height: 36vh; overflow-y: auto" no-body>
          <b-table
            small
            :items="active_calls"
            :fields="active_call_fields"
            striped
            responsive="sm"
          >
            <template #cell(Status)="data">
              <feather-icon
                :icon="state_labels[data.value].icon"
                :class="state_labels[data.value].color"
              ></feather-icon>
              {{ state_labels[data.value].display_name }}
            </template>
          </b-table>
        </b-card>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import {
  BCardFooter,
  BButtonGroup,
  BSpinner,
  BInputGroupPrepend,
  BInputGroup,
  VBTooltip,
  BProgress,
  BTable,
  BContainer,
  BBadge,
  BCardHeader,
  BCardTitle,
  BCardBody,
  BMedia,
  BMediaAside,
  BAvatar,
  BImg,
  BButton,
  BCol,
  BRow,
  BCard,
  BCardText,
  BTabs,
  BTab,
  BMediaBody,
  BModal,
  BFormInput,
  BFormGroup,
  BDropdown,
  BDropdownItem,
  BFormCheckbox,
  BListGroup,
  BListGroupItem,
} from "bootstrap-vue";
import vSelect from "vue-select";
import { $themeColors } from "@themeConfig";
import ChartjsComponentBarChart from "./ChartjsComponentBarChart.vue";
import chartjsData from "./chartjsData";
import VoyceTableWithoutCard from "../../../CustomComponents/VoyceTableWithoutCard.vue"
import draggable from "vuedraggable";

/* eslint-disable global-require */
const $trackBgColor = "#e9ecef";
export default {
  components: {
    BDropdown,
    BDropdownItem,
    draggable,
    BMediaBody,
    BTabs,
    BTab,
    VoyceTableWithoutCard,
    BCardFooter,
    BButtonGroup,
    BSpinner,
    BInputGroupPrepend,
    BInputGroup,
    BMediaAside,
    BAvatar,
    BMedia,
    BFormGroup,
    BProgress,
    BTable,
    BContainer,
    BBadge,
    ChartjsComponentBarChart,
    vSelect,
    BButton,
    BCol,
    BRow,
    BCard,
    BCardText,
    BCardHeader,
    BCardTitle,
    BCardBody,
    BMedia,
    BModal,
    BImg,
    VBTooltip,
    BFormInput,
    BFormCheckbox,
    BListGroup,
    BListGroupItem,

  },
  props: {
    getCampaignDetails: Function,
  },
  directives: {
    "b-tooltip": VBTooltip,
  },
  data() {
    return {
      lang: _l(window.localStorage.getItem("language") || "tr"),
      is_progress: true,
      settings_modal: false,
      tabIndex: 0,
      activeClass: ['border-primary', 'rounded', 'mb-1'],
      passiveClass: ['border-dotted', 'rounded', 'mb-1'],
      socket: {},
      selected_project_name: "",
      selected_campaign: {
        status: "",
      },
      other_permission: [],

      list1: [],
      list2: [],
      selectable_columns: [],
      unselectable_columns: [],
      tmp_potential_columns: [],
      tmp_unpotential_columns: [],
      campaigns: [],
      projects: [],
      tc: [],
      ivr: [],
      lg: [],
      lines: [],
      columns: [],
      agent_data: [],
      custom_key: 123,
      call_summary_interval: "",
      chart_interval: "",
      campaign_data: [
        // {
        //   key: globalThis._lang("t_campaignStatus"),
        //   value: "-",
        // },
        {
          key: globalThis._lang("t_campaignTotalCustomersToCall"),
          value: "-",
          iconClass: ['bi', 'bi-people-fill', 'font-medium-1']

        },
        {
          key: globalThis._lang("t_campaignTotalPhonesToCall"),
          value: "-",
          iconClass: ['bi', 'bi-person-lines-fill', 'font-medium-1']

        },
        {
          key: globalThis._lang("t_campaignCallPerAgent"),
          value: "-",
          iconClass: ['bi', 'bi-person-gear', 'font-medium-1']

        },
        {
          key: globalThis._lang("t_campaignAbandonRate"),
          value: "-",
          iconClass: ['bi', 'bi-telephone-x', 'font-medium-1']

        },

        // {
        //   key: globalThis._lang("t_campaignFilterCriteria"),
        //   value: "-",
        // },
        {
          key: globalThis._lang("t_campaignSortingCriteria"),
          value: "-",
          iconClass: ['bi', 'bi-sort-up', 'font-medium-1']

        },
        {
          key: globalThis._lang("t_campaignRemainingCycles"),
          value: "-",
          iconClass: ['bi', 'bi-arrow-repeat', 'font-medium-1']

        },
      ],
      call_data: [
        {
          key: globalThis._lang("t_campaignRemainingPhonesInCycle"),
          value: "-",
          iconClass: ['bi', 'bi-list-columns-reverse', 'font-medium-1']
        },
        {
          key: globalThis._lang("t_scheduledCalls"),
          value: "-",
          iconClass: ['bi', 'bi-clock', 'font-medium-1']
        },
        {
          key: globalThis._lang("t_closedByDailyAttempts"),
          value: "-",
          iconClass: ['bi', 'bi-check', 'font-medium-1']
        },
        {
          key: globalThis._lang("t_closedByTotalAttempts"),
          value: "-",
          iconClass: ['bi', 'bi-check-all', 'font-medium-1']
        },
        // {
        //   key: "Çağrı Sayısı / Saat",
        //   value: "-",
        // },
        {
          key: globalThis._lang("t_campaignTotalPhonesCalled"),
          value: "-",
          iconClass: ['bi', 'bi-telephone-outbound', 'font-medium-1']
        },
        {
          key: globalThis._lang("t_campaignConnectedCall"),
          value: "-",
          iconClass: ['bi', 'bi-headset', 'font-medium-1']
        },
        {
          key: globalThis._lang("t_campaignWaitingInQueue"),
          value: "-",
          iconClass: ['bi', 'bi-hourglass-split', 'font-medium-1']
        },
        // {
        //   key: globalThis._lang("t_campaignAbandonRate"),
        //   value: '-',
        // },
      ],
      active_calls: [],
      active_call_fields: [
        {
          key: "RemoteNumber",
          label: globalThis._lang("t_number"),
          sortable: true,
        },
        { key: "Status", label: globalThis._lang("t_status"), sortable: true },
        { key: "Line", label: globalThis._lang("t_line"), sortable: true },
        { key: "Agent", label: globalThis._lang("t_agent"), sortable: true },
        {
          key: "CustomerNumber",
          label: globalThis._lang("t_customerId"),
          sortable: true,
        },
        {
          key: "CustomerName",
          label: globalThis._lang("t_nameSurname"),
          sortable: true,
        },
      ],
      fc_fields: [
        {
          key: "finish_code",
          label: globalThis._lang("t_finishCode"),
          sortable: true,
        },
        { key: "count", label: globalThis._lang("t_count"), sortable: true },
        { key: "rate", label: globalThis._lang("t_rate"), sortable: true },
        {
          key: "category",
          label: globalThis._lang("t_category"),
          sortable: true,
        },
      ],
      fc_data: [],
      call_summary: {
        TotalCall: 0,
        SpokenCall: 0,
        SpokenRate: 0,
        NoSpokenCall: 0,
        NoAttemptsCall: 0,
        AttemptsRate: 0,
        ReachRate: 0,
        AbandonCall: 0,
      },
      fc_category_colors: {
        Başarılı: "success",
        Başarısız: "warning",
        Randevu: "primary",
        Diğer: "secondary",
        Sistem: "secondary",
      },
      state_labels: {
        CallPlaced: {
          display_name: globalThis._lang("t_callPlaced"),
          icon: "PhoneIcon",
          color: "text-warning",
        },
        CallWentToAgent: {
          display_name: globalThis._lang("t_callWentToAgent"),
          icon: "PhoneForwardedIcon",
          color: "text-primary",
        },
        "CallJoinedQueue": {
          display_name: globalThis._lang("t_callJoinedQueue"),
          icon: "PhoneForwardedIcon",
          color: "text-primary",
        },
        CallPickedUp: {
          display_name: globalThis._lang("t_callPickedUp"),
          icon: "PhoneCallIcon",
          color: "text-success",
        },
        CallRejected: {
          display_name: globalThis._lang("t_callRejected"),
          icon: "PhoneOffIcon",
          color: "text-danger",
        },
        NoRouteToDestination: {
          display_name: globalThis._lang("t_noRouteToDestination"),
          icon: "PhoneOffIcon",
          color: "text-danger",
        },
      },
      ivr_type_categories: [
        {
          internal_name: 'transfer_queue',
          display_name: globalThis._lang('t_transferQueue'),
        },
        {
          internal_name: 'transfer_ivr',
          display_name: globalThis._lang('t_transferIVR'),
        },
        {
          internal_name: 'hangup',
          display_name: globalThis._lang('t_close'),
        },
      ],
      total_finish_code_counts: 0,
      column_labels: {
        NoAnswer: "s_Cevapsız",
        Busy: "s_Meşgul",
        CallRejected: "s_Reddedildi",
        FaultyCall: "s_HatalıArama",
        NetworkOutOfOrder: "s_NetworkHatası",
        FateUnknown: "s_Bilinmiyor",
        ConnectivityError: "s_BağlantıHatası",
        FaultyNumber: "s_HatalıNumara",
        AnsweringMachine: "s_Makine",
        NoRouteToDestination: "s_OperatördenÇıkmadı",
        ProblemEstablishingCall: "s_ÇağrıKurulamadı",
        Unreachable: "s_ Kapalı/Erişilmez",
      },
      customer_column_labels: {
        status: globalThis._lang("t_customersStatus"),
        assigned_agent: globalThis._lang("t_assigned_agent"),
        attempts: globalThis._lang("t_attempts"),
        campaign: globalThis._lang("t_campaign"),
        finish_code: globalThis._lang("t_finish_code"),
        insert_date: globalThis._lang("t_insert_date"),
        action_date: globalThis._lang("t_action_date"),
        create_date: globalThis._lang("t_create_date"),
        ex_agent: globalThis._lang("t_ex_agent"),
        assign_date: globalThis._lang("t_assign_date"),
      },
      settings: {},

      chartjsData,

      chartData: [],
      chartClone: {},
      chartColor: [
        $themeColors.primary,
        $themeColors.warning,
        $themeColors.secondary,
        $themeColors.info,
        $themeColors.danger,
      ],
      chartSeries: [54.4, 6.1, 14.6, 4.2, 8],
      browserData: [
        {
          browserImg: require("@/assets/images/icons/google-chrome.png"),
          name: "Google Chrome",
          usage: "-",
        },
        {
          browserImg: require("@/assets/images/icons/mozila-firefox.png"),
          name: "Mozila Firefox",
          usage: "-",
        },
        {
          browserImg: require("@/assets/images/icons/apple-safari.png"),
          name: "Apple Safari",
          usage: "-",
        },
        {
          browserImg: require("@/assets/images/icons/internet-explorer.png"),
          name: "Internet Explorer",
          usage: "-",
        },
        {
          browserImg: require("@/assets/images/icons/opera.png"),
          name: "Opera Mini",
          usage: "-",
        },
      ],








      spokeInfoObject: {
        AbandonCall: {
          displayName: globalThis._lang('t_abandonedCount'),
          iconClass: ['bi', 'bi-people'],
          avatarColor: 'light-primary',
          descriptionText: globalThis._lang('t_mrAbandonedCalls'),
        },
        AttemptsRate: {
          displayName: globalThis._lang('t_attemptsRate'),
          iconClass: ['bi', 'bi-telephone-plus'],
          avatarColor: 'light-primary',
          descriptionText: globalThis._lang('t_attemptsRateInfo'),
        },
        NoAttemptsCall: {
          displayName: globalThis._lang('t_nAttemptsCall'),
          iconClass: ['bi', 'bi-headset'],
          avatarColor: 'light-primary',
          descriptionText: globalThis._lang('t_noAttemptsCallInfo'),
        },
        NoSpokenCall: {
          displayName: globalThis._lang('t_nonSpokenCall'),
          iconClass: ['bi', 'bi-telephone-minus'],
          avatarColor: 'light-primary',
          descriptionText: globalThis._lang('t_noSpokenCallInfo'),
        },
        ReachRate: {
          displayName: globalThis._lang('t_reachRate'),
          iconClass: ['bi', 'bi-person-dash'],
          avatarColor: 'light-primary',
          descriptionText: globalThis._lang('t_reachRateInfo'),
        },
        SpokenCall: {
          displayName: globalThis._lang('t_spokenCall'),
          iconClass: ['bi', 'bi-person-check'],
          avatarColor: 'light-primary',
          descriptionText: globalThis._lang('t_spokenCallInfo'),
        },
        SpokenRate: {
          displayName: globalThis._lang('t_spokenCallRate'),
          iconClass: ['bi', 'bi-check-circle'],
          avatarColor: 'light-primary',
          descriptionText: globalThis._lang('t_spokenRateInfo'),
        },
        TotalCall: {
          displayName: globalThis._lang('t_total'),
          iconClass: ['bi', 'bi-telephone-x'],
          avatarColor: 'light-primary',
          descriptionText: globalThis._lang('t_totalCallInfo'),
        },
      },
      chart: {
        series: [65],
        options: {
          grid: {
            show: false,
            padding: {
              left: -15,
              right: -15,
              top: -12,
              bottom: -15,
            },
          },
          colors: [$themeColors.primary],
          plotOptions: {
            radialBar: {
              hollow: {
                size: "22%",
              },
              track: {
                background: $trackBgColor,
              },
              dataLabels: {
                showOn: "always",
                name: {
                  show: false,
                },
                value: {
                  show: false,
                },
              },
            },
          },
          stroke: {
            lineCap: "round",
          },
        },
      },
    };
  },
  methods: {
    save_campaign_settings: async function () {
      this.selected_campaign.settings.sort_criteria = this.list2;
      var response = (
        await this.$http_in.put(`spv/v1/CampaignSettings`, {
          data: this.selected_campaign.settings,
          id: this.selected_campaign._id,
        })
      ).data;

      console.log("this.selected_campaign", this.selected_campaign);
      await this.$http_in.get(
        `dlr/v1/RefreshCampaign/${this.selected_campaign.internal_name}`
      );

      await this.changeCampaignStatus('recycling');

      if (response && response.acknowledged) {
        this.$swal({
          title: globalThis._lang("t_processSuccess"),
          text: globalThis._lang("t_hasBeenCreatedSuccessfully"),
          icon: "success",
          customClass: {
            confirmButton: "btn btn-primary",
          },
          buttonsStyling: false,
        });
      } else {
        this.$swal({
          title: globalThis._lang("t_error"),
          text: globalThis._lang("t_AProblemOccurredwhileProcessing"),
          icon: "error",
          customClass: {
            confirmButton: "btn btn-primary",
          },
          buttonsStyling: false,
        });
      }
    },
    clearSchCallTimes() {
      this.$swal({
        title: globalThis._lang("t_warning"),
        text: `Zamanı gelmeyen çağrılar sıfırlanacak, onaylıyor musunuz?`,
        icon: "info",
        allowOutsideClick: false,
        allowEscapeKey: false,
        closeOnClickOutside: false,
        showCancelButton: true,
        confirmButtonText: this.lang("t_yes"),
        cancelButtonText: this.lang("t_cancel"),
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        this.socket.emit("CampaignDeScheduleAllCalls", {
          Campaign: this.selected_campaign.internal_name,
        });
      });
    },
    clearDailyAttempts() {
      this.$swal({
        title: globalThis._lang("t_warning"),
        text: `Günlük temastan kapanan datalar açılacak. Bunun için kampanya ayarlarından günlük teması arttırmanız gerekiyor. Bu işlem geri alınamaz. Onaylıyor musunuz?`,
        icon: "info",
        allowOutsideClick: false,
        allowEscapeKey: false,
        closeOnClickOutside: false,
        showCancelButton: true,
        confirmButtonText: this.lang("t_yes"),
        cancelButtonText: this.lang("t_cancel"),
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        this.socket.emit("CampaignResetDailyAttempts", {
          Campaign: this.selected_campaign.internal_name,
        });
        this.$bvToast.toast(
          `Kampaya ayarlarından günlük teması arttırmanız gerekiyor. Aksi halde aramalar başlamayacaktır.`,
          {
            title: `${globalThis._lang("t_info")}`,
            toaster: "b-toaster-top-right",
            solid: true,
            appendToast: false,
            variant: "success",
          }
        );
      });
    },
    clearTotalAttempts() {
      this.$swal({
        title: globalThis._lang("t_warning"),
        text: `Toplam temastan kapanan datalar açılacak. Bunun için kampanya ayarlarından toplam teması arttırmanız gerekiyor. Bu işlem geri alınamaz. Onaylıyor musunuz?`,
        icon: "info",
        allowOutsideClick: false,
        allowEscapeKey: false,
        closeOnClickOutside: false,
        showCancelButton: true,
        confirmButtonText: this.lang("t_yes"),
        cancelButtonText: this.lang("t_cancel"),
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        this.socket.emit("CampaignResetTotalAttempts", {
          Campaign: this.selected_campaign.internal_name,
        });
        this.$bvToast.toast(
          `Kampaya ayarlarından toplam teması arttırmanız gerekiyor. Aksi halde aramalar başlamayacaktır.`,
          {
            title: `${globalThis._lang("t_info")}`,
            toaster: "b-toaster-top-right",
            solid: true,
            appendToast: false,
            variant: "success",
          }
        );
      });
    },
    changeCampaignStatus: async function (status) {
      this.socket.emit("CampaignControl", {
        Campaign: this.selected_campaign.internal_name,
        Status: status,
      });
    },
    get_campaign_finish_codes: async function () {
      var response = (
        await this.$http_in.get(
          `spv/v1/DialerSummary/${this.selected_campaign.internal_name}`
        )
      ).data;

      this.fc_data = [];
      this.total_finish_code_counts = 0;
      for (const item of response) {
        this.total_finish_code_counts += item.count;
      }

      for (const item of response) {
        // console.log("globalThis.finish_codes.find(j => j.display_name == item)",globalThis.finish_codes.find(j => j.finish_code == item) );
        let _fc = globalThis.finish_codes.find(
          (j) => j.finish_code == item._id
        );
        let _category = _fc
          ? globalThis.finish_categories.find((e) => e._id == _fc.category_id)
          : undefined;
        this.fc_data.push({
          finish_code: this.column_labels.hasOwnProperty(item._id)
            ? this.column_labels[item._id]
            : item._id,
          count: item.count,
          rate: item.count,
          category: _category
            ? globalThis.finish_categories.find((e) => e._id == _fc.category_id)
              .category
            : "Sistem",
        });
      }

      // chartjsData.latestBarChart.data.labels = response.map(e => { return this.column_labels.hasOwnProperty(e._id) ? this.column_labels[e._id] : e._id });
      // chartjsData.latestBarChart.data.datasets[0].data = response.map(e => e.count);
      // this.custom_key = Math.floor(Math.random() * 101);
    },
    get_call_summary: async function () {
      var response = (
        await this.$http_in.get(
          `spv/v1/DialerCallSummary/${this.selected_campaign.internal_name}`
        )
      ).data;

      this.call_summary = {
        TotalCall: 0,
        SpokenCall: 0,
        SpokenRate: 0,
        NoSpokenCall: 0,
        NoAttemptsCall: 0,
        AttemptsRate: 0,
        ReachRate: 0,
      };

      // for (const item of response) {
      //   if (item._id == 'ANSWERED') {
      //     this.call_summary.success = item.count;
      //   } else {
      //     this.call_summary.fail = item.count;
      //   }
      // }
      // this.call_summary.total = this.call_summary.success + this.call_summary.fail;
      // this.call_summary.rate = Math.round(this.call_summary.success / this.call_summary.total * 100) || 0;

      this.call_summary = {
        ...response,
        SpokenRate: response.SpokenRate ?? 0,
        ReachRate: response.ReachRate ?? 0,
        AttemptsRate: response.AttemptsRate ?? 0,
      };
    },
    async update_settings(item) {
      var _self = this;
      console.log("item", item);
      if (item && item.settings && item.settings.queue) {
        await this.$http_in.get(
          `dlr/v1/RefreshCampaignCallCountsByQueue/${item.settings.queue}`
        );
      }


      this.get_campaign_finish_codes();
      this.get_call_summary();
      if (this.chart_interval != "") {
        clearInterval(this.chart_interval);
      }
      if (this.call_summary_interval != "") {
        clearInterval(this.call_summary_interval);
      }
      this.chart_interval = setInterval(function () {
        _self.get_campaign_finish_codes();
      }, parseInt(item.settings.statistics_period) * 60 * 1000);
      this.call_summary_interval = setInterval(function () {
        _self.get_call_summary();
      }, 1 * 60 * 1000);

      this.selected_project_name = this.projects.find(
        (e) => e.internal_name == this.selected_campaign.project
      ).display_name;
      // console.log('this.projects.find(e=> e.internal_name = this.selected_campaign.project)', this.projects.find(e=> e.internal_name = this.selected_campaign.project));

      this.socket.emit("RegisterToCampaignInfo", {
        Campaign: this.selected_campaign,
      });

      if (
        this.selected_campaign.settings != undefined ||
        this.selected_campaign.settings != null
      ) {
        this.settings = { ...this.selected_campaign.settings };
      } else {
        this.settings = {
          priortiy: "100",
          max_call: "200",
          call_per_agent: "3",
          statistics_period: "1",
          line_group: "dialplan",
          timeout_second: "30",
          abandon_rate: "",
          target: "",
          machine_detection: "",
          sort_criteria: [],
          max_recyle: "1",
          next_call_time: "0",
          after_call_statu: `${globalThis._lang("t_afterCall")}`,
          missed_call_abandon_time: "30",
          max_phone_touch: "5",
          max_contact_touch: "",
          max_day_touch: "",
          day_object_effect: "",
          missed_call_auto_sch: "",
          busy_call_auto_sch: "",
          system_hangup_call_auto_sch: "",
          remote_hangup_call_auto_sch: "",
          machine_call_auto_sch: "",
          fax_call_auto_sch: "",
          time_condition: "",
          live_contact: {
            category: "",
            target: "",
          },
          no_idle_agent: {
            category: "",
            target: "",
          },
          machine: {
            category: "",
            target: "",
          },
          fax: {
            category: "",
            target: "",
          },
          rules: [],
          max_phone_total_attempts: "12",
        };
      }

      if (this.selected_campaign.settings != null) {
        this.list2 = this.selected_campaign.settings.sort_criteria;
        if (
          this.selected_campaign.settings.sort_criteria != null &&
          this.selected_campaign.settings.sort_criteria != []
        ) {
          for (const item of this.selected_campaign.settings.sort_criteria) {
            this.list1 = this.list1.filter(
              (e) => e.internal_name != item.internal_name
            );
          }
        }
      }

      var queue = {
        internal_name: "",
        display_name: "",
      };
      console.log(
        "this.settings.live_contact.target",
        this.settings.live_contact.target
      );

      if (this.settings.live_contact.target != "") {
        var _ivr = this.ivr.find(
          (e) => e.internal_name === this.settings.live_contact.target
        );
        console.log("_ivr", _ivr);
        if (_ivr.ivr_object[0].name == "queue") {
          queue["internal_name"] = _ivr.ivr_object[0].target.internal_name;
          queue["display_name"] = _ivr.ivr_object[0].target.display_name;
        }
      }

      this.settings.queue = queue.internal_name;
      this.set_current_queue();
      this.getCampaignDetails(this.selected_campaign);
    },
    getCustomerColumns: async function () {
      var response = (
        await this.$http_in.get(
          `spv/v1/ProjectColumns/${this.selected_campaign.project}_customers`
        )
      ).data;
      console.log("response", response);
      let _data = [];
      for (const item of response[0].allkeys) {
        if (
          [
            "active",
            "_id",
            "cycles",
            "TCKN_masked",
            "notes",
            "phones",
            "products",
            "campaign",
            "sms",
            "extra_data",
            "daily_attempts",
            "update_date",
            "assigned_agent2",
          ].includes(item)
        ) {
          continue;
        }
        _data.push({
          internal_name: item,
          display_name:
            this.customer_column_labels[item] == undefined
              ? item
              : this.customer_column_labels[item],
          desc: false,
        });
      }
      // this.editedItem.column = _data[0];
      this.list1 = _data;
      this.columns = _data;
      //this.editedItem.column = _data[0];
      console.log("columns", _data);
    },
    set_current_queue() {
      var queue = {
        internal_name: "",
        display_name: "",
      };
      console.log(
        "this.settings.live_contact.target",
        this.settings.live_contact.target
      );

      if (this.settings.live_contact.target && this.settings.live_contact.target != "") {
        var _ivr = this.ivr.find(
          (e) => e.internal_name === this.settings.live_contact.target
        );
        console.log("_ivr", _ivr);
        if (_ivr.ivr_object[0].name == 'queue') {
          queue['internal_name'] = _ivr.ivr_object[0].target.internal_name;
          queue['display_name'] = _ivr.ivr_object[0].target.display_name;
        }
        else if (_ivr.ivr_object[0].name == 'menu') {
          for (const item of _ivr.ivr_object[0].tasks) {
            if (item.name == 'queue') {
              queue['internal_name'] = item.target.internal_name;
              queue['display_name'] = item.target.display_name;
              break;
            }
          }
        }
      }
      console.log("queue", queue);
      this.settings.queue = queue.internal_name;
      this.settings.queue_display_name = queue.display_name;

      this.settings.trunk = this.settings.line_group.internal_name;
      this.settings.trunk_prefix = this.settings.line_group.prefix;
      this.settings.caller_id = this.settings.line_group.caller_number;
      this.selected_campaign.settings = { ...this.settings };
    },
    getStatusName(status) {
      var durum = "";
      switch (status) {
        case "running":
          durum = globalThis._lang("t_started");
          break;
        case "stopped":
          durum = globalThis._lang("t_stopped");
          break;
        case "recycling":
          durum = globalThis._lang("t_recycling");
          break;
        case "completed":
          durum = globalThis._lang("t_isCompleted");
          break;
        default:
          durum = "Durduruldu";
          break;
      }
      return durum;
    },
    get_campaign: async function () {
      var response = (await this.$http_in.get(`spv/v1/Campaign`)).data;

      this.campaigns = response;


    },
    get_projects: async function () {
      var response = (await this.$http_in.get(`spv/v1/Project`)).data;

      this.projects = response;
    },
    get_IvrManagementTargets: async function () {
      var response = (await this.$http_in.get(`spv/v1/IvrManagementTargets`))
        .data;

      this.tc = response.TimeConditions;
      this.ivr = response.Ivr;
    },
    get_TimeConditions: async function () {
      var response = (
        await this.$http_in.get(`spv/v1/TimeCondition`, {
          headers: {
            authorization: globalThis.token,
          },
        })
      ).data;

      this.tc = response;
    },
    get_LineGroup: async function () {
      var response = (await this.$http_in.get(`spv/v1/LineGroup`)).data;

      this.lg = response.map(e => {
        return { ...e, type: 'line_group' };
      });
      console.log("asd", response);
      // this.lg.unshift({
      //   internal_name: 'dialplan',
      //   display_name: 'Arama Planı',
      // });
    },
    getDialerPrecall: async function () {
      var response = (await this.$http_in.get(`spv/v1/DialerPrecall`)).data;

      this.lg = [...this.lg, ...response.map(e => {
        return { ...e, type: 'dialer_precall' };
      })];


    },
    get_line: async function () {
      var response = (await this.$http_in.get(`spv/v1/Line`)).data;

      this.lines = response;
    },
  },
  mounted: async function () {
    this.is_progress = true;
    this.other_permission = globalThis.permissions["other"] || [];

    await Promise.all([
      this.get_campaign(),
      this.get_projects(),
      this.get_TimeConditions(),
      this.get_IvrManagementTargets(),
      this.get_LineGroup(),
      this.getDialerPrecall(),
    ]).then(values => { }).catch(error => { console.error(error); });

    this.socket = new this.$ws(
      `${globalThis.env["PROTOCOL"]}://${globalThis.env["DIALER_URL"]}`,
      {
        forceNew: true,
        extraHeaders: {
          Authorization: `Bearer ${globalThis.qm_token}`,
        },
      }
    );
    this.socket.on("connect", async () => {
      console.log("DIALER CONNECT");
      let _campaign = localStorage.getItem("dialerCampaign");
      if (![undefined, null].includes(_campaign)) {
        _campaign = JSON.parse(_campaign);
      }
      if (_campaign && this.campaigns.find(e => e.internal_name == _campaign.internal_name)) {
        this.selected_campaign = _campaign;
        await this.update_settings(this.selected_campaign);
        this.getCustomerColumns()

      } else {
        this.selected_campaign = this.campaigns[0];
        await this.update_settings(this.selected_campaign);
        this.getCustomerColumns()

      }



      this.socket.on("CampaignUpdate", (msg) => {
        console.log("CampaignUpdate", msg);
        if (![undefined, null].includes(msg)) {
          if (msg.Name === this.selected_campaign.internal_name) {
            this.agent_data = [];

            this.agent_data.push({
              key: globalThis._lang("t_project"),
              value: this.selected_project_name,
              iconClass: ['bi', 'bi-clipboard-data', 'font-medium-1']

            });
            this.agent_data.push({
              key: globalThis._lang("t_queue"),
              value: msg.QueueDisplayName,
              iconClass: ['bi', 'bi-diagram-3', 'font-medium-1']
            });

            this.agent_data.push({
              key: globalThis._lang("t_total"),
              value: msg.TotalAgentCount,
              iconClass: ['bi', 'bi-people', 'font-medium-1']
            });
            this.agent_data.push({
              key: globalThis._lang("t_available"),
              value: msg.AvailableAgentCount,
              iconClass: ['bi', 'bi-person', 'font-medium-1']
            });

            this.active_calls = msg.CurrentCallInfo;

            var _filter = "";
            if (
              !["", null, undefined].includes(this.selected_campaign.filter)
            ) {
              this.selected_campaign.filter.map((e) => {
                _filter += e.column + e.operand + e.value + ",";
              });
            }

            var _sort_criteria = "";
            if (
              !["", null, undefined].includes(this.selected_campaign.filter)
            ) {
              this.tmp_unpotential_columns.map((e) => {
                console.log(e);
                _sort_criteria += e.replace("##", "_") + ",";
              });
            }

            this.selected_campaign.status = msg.RunningStatus;
            // this.campaign_data[0].value = this.getStatusName(msg.RunningStatus);
            this.campaign_data[0].value = msg.ContactCount;
            this.campaign_data[1].value = msg.TotalCalls;
            this.campaign_data[2].value = msg.CallPerAgent;
            this.campaign_data[3].value = msg.AbandonRate;
            // this.campaign_data[3].value = msg.Priority;
            // this.campaign_data[4].value = _filter.substr(0, _filter.length - 1);
            this.campaign_data[4].value = _sort_criteria.substr(
              0,
              _sort_criteria.length - 1
            );
            this.campaign_data[5].value = msg.RemainingCycles;

            this.call_data[0].value = msg.CurrentCycleCalls;
            this.call_data[1].value = msg.ScheduledCallCount ?? 0;

            // this.call_data[3].value = msg.CallsPerHour;
            this.call_data[2].value = msg.ClosedByDailyAttempts ?? 0;
            this.call_data[3].value = msg.ClosedByTotalAttempts ?? 0;
            // this.call_data[3].value = Number(msg.TotalCalls) - Number(msg.CurrentCycleCalls);
            this.call_data[4].value = msg.CurrentRingingCalls;
            this.call_data[5].value = msg.ConnectedCalls;
            this.call_data[6].value = msg.CallsWaitingInQueue;
          }
        }
      });
    });



    // const this_data = JSON.parse(localStorage.getItem("dialer_cache"));

    // if (![undefined, null].includes(this_data)) {
    //   this.selected_campaign = this_data.selected_campaign;
    //   this.update_settings(this.selected_campaign);
    // }
    this.is_progress = false;
  },
  beforeDestroy: function () {
    localStorage.setItem("dialerCampaign", JSON.stringify(this.selected_campaign));

    this.socket.disconnect();
    if (this.chart_interval != "") {
      clearInterval(this.chart_interval);
    }
    if (this.call_summary_interval != "") {
      clearInterval(this.call_summary_interval);
    }
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";

.border-dotted {
  border-style: dashed;
  border-width: 2px;
  border-color: rgba($color: #9ca0a4, $alpha: 0.4);
}

@keyframes slideDown {
  from {
    transform: translateY(-100%);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

.animated-text {
  animation: slideDown 0.5s ease-out;
}
</style>
